//
// Scroll
//


// Customize native scrollbars only for desktop mode
@include media-breakpoint-up(lg) {
    main,
    span,
    ol,
    ul,
    pre,
    div {
        // Firefox
        scrollbar-width: thin;

        // Webkit
        &::-webkit-scrollbar {            
            width: var(--kt-scrollbar-size);
            height: var(--kt-scrollbar-size);
        }

        ::-webkit-scrollbar-track {
           background-color: transparent;
        }

        ::-webkit-scrollbar-thumb {
            @include border-radius(var(--kt-scrollbar-size));
        }

        // Default color
        @include scrollbar-color(var(--kt-scrollbar-color), var(--kt-scrollbar-hover-color));
    }
}

// Scroll
.scroll {
    overflow: scroll;
    position: relative;

    // Tablet & mobile modes
    @include media-breakpoint-down(lg) {
        overflow: auto;
    }
}

.scroll-x {
    overflow-x: scroll;
    position: relative;

    // Tablet & mobile modes
    @include media-breakpoint-down(lg) {
        overflow-x: auto;
    }
}

.scroll-y {
    overflow-y: scroll;
    position: relative;

    // Tablet & mobile modes
    @include media-breakpoint-down(lg) {
        overflow-y: auto;
    }
}

.hover-scroll,
.hover-scroll-overlay {    
    position: relative;
    
    // Desktop mode
    @include media-breakpoint-up(lg) {
        overflow: hidden;       

        @include for-firefox {
            overflow: scroll;
        }

        .safari-mode & {
            overflow: scroll;
        }

        &::-webkit-scrollbar {
            width: var(--kt-scrollbar-overlay-size);
            height: var(--kt-scrollbar-overlay-size);
        }

        &::-webkit-scrollbar-thumb {
            background-clip: content-box;
            border: var(--kt-scrollbar-overlay-space) solid transparent;
            @include border-radius(var(--kt-scrollbar-overlay-size));
        }

        &:hover {
            overflow: overlay;
        }
    }

    // Tablet & mobile modes
    @include media-breakpoint-down(lg) {
        overflow: auto;
    }   
}

.hover-scroll-y,
.hover-scroll-overlay-y {    
    position: relative;
    
    // Desktop mode
    @include media-breakpoint-up(lg) {
        overflow-y: hidden;       

        @include for-firefox {
            overflow-y: scroll;
        }

        .safari-mode & {
            overflow-y: scroll;
        }

        &::-webkit-scrollbar {
            width: var(--kt-scrollbar-overlay-size);
        }

        &::-webkit-scrollbar-thumb {
            background-clip: content-box;
            border: var(--kt-scrollbar-overlay-space) solid transparent;
            @include border-radius(var(--kt-scrollbar-overlay-size));
        }

        &:hover {
            overflow-y: overlay;
        }
    }

    // Tablet & mobile modes
    @include media-breakpoint-down(lg) {
        overflow-y: auto;
    }   
}

.hover-scroll-x,
.hover-scroll-overlay-x {    
    position: relative;
    
    // Desktop mode
    @include media-breakpoint-up(lg) {
        overflow-x: hidden;       

        @include for-firefox {
            overflow-x: scroll;
        }

        .safari-mode & {
            overflow-x: scroll;
        }

        &::-webkit-scrollbar {
            height: var(--kt-scrollbar-overlay-size);
        }

        &::-webkit-scrollbar-thumb {
            background-clip: content-box;
            border: var(--kt-scrollbar-overlay-space) solid transparent;
            @include border-radius(var(--kt-scrollbar-overlay-size));
        }

        &:hover {
            overflow-x: overlay;
        }
    }

    // Tablet & mobile modes
    @include media-breakpoint-down(lg) {
        overflow-x: auto;
    }   
}

// Utilities  
.scroll-ps {
    padding-left: var(--kt-scrollbar-size) !important;
}

.scroll-ms {
    margin-left: var(--kt-scrollbar-size) !important;
}

.scroll-mb {
    margin-bottom: var(--kt-scrollbar-size) !important;
}

.scroll-pe {
    padding-right: var(--kt-scrollbar-size) !important;
}

.scroll-me {
    margin-right: var(--kt-scrollbar-size) !important;
}

.scroll-px {
    padding-left: var(--kt-scrollbar-size) !important;
    padding-right: var(--kt-scrollbar-size) !important;
}

.scroll-mx {
    margin-left: var(--kt-scrollbar-size) !important;
    margin-right: var(--kt-scrollbar-size) !important;
}